.qs-datepicker-container {
  tab-index: 0;
}

$width: 225px;
$transition: 0.3s;
$font-size: $width * 0.056;

/*
  All a user has to do to change the calendar size is
  change the font-size on the container and everything
  magically resizes accordingly. Relative units ftw!
*/

.qs-datepicker-container {
  padding: 10px;
  font-size: 1rem;
  color: black;
  position: absolute;
  width: $width;
  display: flex;
  flex-direction: column;
  z-index: 9001;
  user-select: none;
  overflow: hidden;
  background: white;


  &.qs-position-bl {
    border-radius: 0 0 5px 5px;
    border-top: 2px solid #6b77d0;
    box-shadow: 0 23px 45px rgba(0, 0, 0, 0.3);
    transform: translate(-13px, 8px);
  }

  &.qs-position-tl {
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid #6b77d0;
    box-shadow: 0 -10px 45px rgba(0, 0, 0, 0.3);
    transform: translate(-13px, -8px);
  }

  * {
    box-sizing: border-box;
    span {
      color: inherit;
    }
  }

  &.qs-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.qs-hidden {
    display: none;
  }
}

.qs-datepicker {
  .qs-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    padding: 0.5em;
    z-index: 1;
    opacity: 1;
    transition: opacity $transition;
    display: flex;
    flex-direction: column;

    span {
      color: #494b54;
    }

    &.qs-hidden {
      opacity: 0;
      z-index: -1;
    }

    .qs-overlay-year {
      // Overlay year input element.
      min-width: 20px;
      min-height: 35px;
      height: 35px;
      max-height: 35px;
      background: #f5f6fa;
      border-radius: 5px;
      font-size: 14px;
      border: none;
      padding: 0.25em 0;
      width: 197px;
      text-align: center;
      margin: 0 auto;
      display: block;

      // https://goo.gl/oUuGkG
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    .qs-close {
      cursor: pointer;
      position: absolute;
      bottom: 16px;
      left: 72px;
      font-size: 12px;
      color: transparent;
      &:after {
        color: #4555d0;
        content: 'Cancel';
      }
    }

    .qs-submit {
      background: #5c6fff;
      border-radius: 15px;
      color: #fff;
      width: 75px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-weight: 500;
      font-size: 12px;
      margin-left: 128px;
      cursor: pointer;

      &.qs-disabled {
        background: #e0e0e0;
        cursor: not-allowed;
      }
    }

    .qs-overlay-month-container {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      justify-content: space-between;
      margin-top: 8px;
    }

    .qs-overlay-month {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 28px;
      cursor: pointer;
      font-size: 14px;
      margin: 1px 7px;
      border-radius: 11px;
      &.active {
        box-shadow: 0px 0px 0px 1px #979797;
        span {
          color: #494b54;
        }
      }
      &:hover {
        background: #edeefb;
      }
    }
  }

  .qs-controls {
    padding: 6px 6px 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    filter: blur(0px);
    transition: filter $transition;
    position: relative;

    &.qs-blur {
      filter: blur(5px);
    }
  }

  .qs-arrow {
    position: absolute;
    top: 10px;
    cursor: pointer;
    transition: background 0.15s;
    border: solid #dadde0;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;

    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      top: -6px;
      left: -6px;
    }

    &.qs-left {
      right: 45px;
      transform: rotate(135deg);
    }

    &.qs-right {
      transform: rotate(-45deg);
      right: 14px;
    }

    &:hover {
      border-color: #4555d0;
    }
  }

  .qs-month-year {
    font-weight: 500;
    color: #333333;
    letter-spacing: -0.2px;
    font-size: 16px;
    line-height: 20px;
    transition: border 0.2s;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid gray;
    }

    &:focus,
    &:active:focus {
      outline: none;
    }
  }

  .qs-month {
    font-weight: bold;
    color: #333333;
  }

  .qs-year {
    color: #333333;
  }

  .qs-squares {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    padding: $width * 0.02;
    filter: blur(0px);
    transition: filter $transition;

    &.qs-blur {
      filter: blur(5px);
    }
  }

  .qs-square {
    width: 26px;
    margin: 1px;
    height: 22px;
    display: flex;
    border-radius: 11px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.1s;
    border: 1px solid #fff;
    margin: 1px;

    &.qs-current {
      box-shadow: 0px 0px 0px 1px #979797;
    }

    &.qs-active {
      background: #4555d0;
      span {
        color: #fff;
      }
    }

    &.qs-range-date-middle {
      background: lighten(lightblue, 10%);
    }

    &.qs-disabled {
      span {
        opacity: 0.2;
      }
    }

    &.qs-empty {
      cursor: default;
    }

    &.qs-disabled {
      cursor: not-allowed;
    }

    &.qs-day {
      cursor: default;
      color: #bfbfbf;
      font-weight: 500;
      font-size: 8px;
      line-height: 19px;
    }

    &:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active) {
      span {
        color: #494b54;
      }
      &:hover {
        background: #edeefb;
      }
    }
  }

  .qs-event {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: $width * 0.03;
      height: $width * 0.03;
      border-radius: 50%;
      background: #07f;
      bottom: 0;
      right: 0;
    }

    &.qs-disabled,
    &.qs-empty {
      &:after {
        background: lighten(#07f, 40);
      }
    }
  }
}
