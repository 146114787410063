.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.public-DraftEditorPlaceholder-root {
  position: absolute;
  color: #c4c6cb;
}
.public-DraftStyleDefault-block {
  line-height: 19px;
  margin-bottom: 3px;
}
.public-DraftEditorPlaceholder-inner {
  line-height: 19px;
}

.draftJsMentionPlugin__mention__29BEd,
.draftJsMentionPlugin__mention__29BEd:visited {
  color: #575f67;
  cursor: pointer;
  display: inline-block;
  background: #d6d9f1;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
  text-decoration: none;
}

.draftJsMentionPlugin__mention__29BEd:hover,
.draftJsMentionPlugin__mention__29BEd:focus {
  color: #677584;
  background: #edf5fd;
  outline: 0; /* reset for :focus */
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  border: 1px solid #eee;
  margin-top: 0.4em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.react-portal-hint__body {
  transition: opacity 0.2s ease-out;
  width: 1px; // need for position calculation
  height: 1px; // need for position calculation
  z-index: 1;
}
.react-portal-hint__body.shown {
  opacity: 1;
}
.react-portal-hint__body.hidden {
  opacity: 0;
}
.react-portal-hint__body.top {
  transform: translateY(10px);
}

// add space for buttons
.public-DraftEditor-content
div[data-block='true']:last-child
> .public-DraftStyleDefault-block
> span:last-child
> span[data-text='true']:after {
  content: '';
  display: block;
  width: 66px;
  margin-left: 5px;
  height: 23px;
  float: right;
}
.has-files
.public-DraftEditor-content
div[data-block='true']:last-child
> .public-DraftStyleDefault-block
> span:last-child
> span[data-text='true']:after {
  display: none !important;
}

.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block;
}

.draftJsToolbar__button__qi1gf {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.draftJsToolbar__button__qi1gf svg {
  fill: #888;
}

.draftJsToolbar__button__qi1gf:hover,
.draftJsToolbar__button__qi1gf:focus {
  background: #f3f3f3;
  outline: 0; /* reset for :focus */
}

.draftJsToolbar__active__3qcpF {
  background: #efefef;
  color: #444;
}

.draftJsToolbar__active__3qcpF svg {
  fill: #444;
}
.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em;
}
.draftJsToolbar__toolbar__dNtBH {
  left: 50%;
  -webkit-transform: translate(-50%) scale(0);
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  z-index: 2;
  box-sizing: border-box;
}

.draftJsToolbar__toolbar__dNtBH:after,
.draftJsToolbar__toolbar__dNtBH:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.draftJsToolbar__toolbar__dNtBH:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}
.draftJsToolbar__toolbar__dNtBH:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}

.draftJsLinkifyPlugin__link__2ittM,
.draftJsLinkifyPlugin__link__2ittM:visited {
  color: #5e93c5;
  text-decoration: none;
}

.draftJsLinkifyPlugin__link__2ittM:hover,
.draftJsLinkifyPlugin__link__2ittM:focus {
  color: #7eadda;
  outline: 0; /* reset for :focus */
  cursor: pointer;
}

.draftJsLinkifyPlugin__link__2ittM:active {
  color: #4a7bab;
}

.draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  margin: 0 18px;
  position: absolute;
  bottom: 45px;
  z-index: 1000;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

.draftJsEmojiPlugin__emojiSelectPopoverClosed__3Kxxq {
  display: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverTitle__3tpXz {
  margin: 0 0 0.3em;
  font-weight: bold;
  padding-left: 9px;
  height: 22px;
  line-height: 2.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m {
  margin: 0 0 0.3em;
  position: relative;
  z-index: 0;
  width: 21em;
  height: 20em;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroup__3zwcE {
  padding: 0 0.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroup__3zwcE:first-child
.draftJsEmojiPlugin__emojiSelectPopoverGroupTitle__2pC51 {
  display: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupTitle__2pC51 {
  margin: 1em 0;
  padding-left: 0.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupList__HQ8_y {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupItem__2pFOS {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelect__28bny {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectList__haFSJ {
  margin: 0.3em;
  padding: 0.3em;
  position: absolute;
  display: -webkit-box;
  display: flex;
  list-style: none;
  border: 1px solid #e0e0e0;
  border-radius: 0.5em;
  background: #fff;
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectItem__2SgvL {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectItem__2SgvL:first-child {
  border-right: 1px solid #e0e0e0;
}

.draftJsEmojiPlugin__emojiSelectPopoverEntry__1ErDJ,
.draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS {
  padding: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS {
  background-color: #efefef;
}

.draftJsEmojiPlugin__emojiSelectPopoverEntryIcon__1yNaC {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}

.draftJsEmojiPlugin__emojiSelectPopoverNav__1Nzd7 {
  margin: 0;
  padding: 0 0.5em;
  display: -webkit-box;
  display: flex;
  width: 20em;
  list-style: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavItem__qydCX {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavEntry__1OiGB,
.draftJsEmojiPlugin__emojiSelectPopoverNavEntryActive__2j-Vk {
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  cursor: pointer;
  color: #bdbdbd;
  background: none;
  border: none;
  outline: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavEntryActive__2j-Vk {
  color: #42a5f5;
}

.draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6 {
  position: absolute;
  right: 0;
  top: 0.3em;
  bottom: 0.3em;
  width: 0.25em;
  background-color: #e0e0e0;
  border-radius: 0.125em;
  opacity: 0.1;
  transition: opacity 0.4s;
}

.draftJsEmojiPlugin__emojiSelectPopoverScrollbarThumb__jGYdG {
  background-color: #000;
  border-radius: 0.125em;
  cursor: pointer;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m:hover .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6 {
  opacity: 0.3;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6:hover,
.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6:active {
  opacity: 0.6;
}
.draftJsEmojiPlugin__emoji__2oqBk {
  background-position: center;
  /* make sure the background the image is only shown once */
  background-repeat: no-repeat;
  background-size: contain;
  /* move it a bit further down to align it nicer with the text */
  vertical-align: middle;

  /*
  We need to limit the emoji width because it can be multiple characters
  long if it is a 32bit unicode. Since the proper width depends on the font and
  it's relationship between 0 and other characters it's not ideal. 1.95ch is not
  the best value, but hopefully a good enough approximation for most fonts.
  */
  display: inline-block;
  overflow: hidden;
  max-width: 1.95ch;
  /*
  Needed for iOS rendering to avoid some icons using a lot of height without
  actually needing it.
  */
  max-height: 1em;
  line-height: inherit;
  margin: -0.2ex 0em 0.2ex;
  /*
  In the past we used opacity: 0 to hide the original Emoji icon no matter what
  system it is. Recently we switched to color: transparent since it started to
  work in recent iOS version.
  */
  color: transparent;

  /*
  Some SVG files (say 2764 for :heart:) don't have default width/height, thus
  may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
  Chrome 61).
  */
  min-width: 1em;
}
.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_ {
  padding: 5px 10px 1px 10px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_:active {
  background-color: #cce7ff;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryFocused__XDntY {
  background-color: #e6f3ff;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryText__2sPjk {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryIcon__1qC2V {
  width: 1em;
  height: 1em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  display: inline-block;
}
.draftJsEmojiPlugin__emojiSuggestions__2ffcV {
  display: none !important; // TODO activate later
  border: 1px solid #eee;
  margin-top: 1.75em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  transform: scale(0);
}
