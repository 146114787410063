@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-Light.woff') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-Regular.woff') format('woff');
    font-weight: normal; /* 400 */
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-Medium.woff') format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-Bold.woff') format('woff');
    font-weight: bold; /* 700 */
}
