$blue: #5bb7db;
$lightgrey: #b6babd;
$grey: #536879;
$black: #000;
$white: #fff;
$body-color: #fff;
$title-color: #2e4a82;
$blue-color: #4b83f0;
$red-color: #ff0000;
$green-color: #62d862;
$gray-color: #797a7e;
$border-color: #d2d5d9;
$gray-l: #dfe2e6;

$header-bckgr: $white;
$header-height: 46px;
$header-font-size: 15px;

$communication-bar-width: 60px;
$communication-bar-bckgr: $blue;

$search-width: 200px;
$search-font-size: 13px;

$order-height: 50px;

$z-index-header: 1010;
$z-index-communication-bar: 1000;

:export {
  activeMenuLinkColor: $grey;
}

$colorpicker-1: #000;
$colorpicker-2: #616161;
$colorpicker-3: #607d8b;
$colorpicker-4: #303f9f;
$colorpicker-5: #311b92;
$colorpicker-6: #388e3c;
$colorpicker-7: #004d40;
$colorpicker-8: #1b5e20;
$colorpicker-9: #7cb342;
$colorpicker-10: #76ff03;
$colorpicker-11: #ffd600;
$colorpicker-12: #ff6f00;
$colorpicker-13: #fb8c00;
$colorpicker-14: #e64a19;
$colorpicker-15: #ffc107;
$colorpicker-16: #3d5afe;
$colorpicker-17: #448aff;
$colorpicker-18: #29b6f6;
$colorpicker-19: #82b1ff;
$colorpicker-20: #64ffda;
$colorpicker-21: #ff0600;
$colorpicker-22: #7c2d1c;
$colorpicker-23: #b71c1c;
$colorpicker-24: #e91e63;
$colorpicker-25: #f48fb1;
