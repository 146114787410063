@import 'reset';
@import 'variables';
@import "toastify";
@import "dateTimePicker";
@import "map";


body {
  font-family: Rubik, sans-serif;
  font-weight: normal;
  line-height: 1.4em;
  color: #333333;
  background-color: $body-color;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

html,
body {
  width: 100%;
  height: 100%;
  min-width: 768px;
  background: #445366;
}

.block {
  display: block !important;
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.draggable-column {
  z-index: 9999;
  background-color: #f7f5fd;
}

.draggable-tab {
  z-index: 9999;
  .tab {
    background-color: white !important;
  }
}

// Styled Scroll

::-webkit-scrollbar-button {
  background-image: none;
  background-repeat: no-repeat;
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgb(245, 246, 250);
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: rgb(71, 82, 100);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(71, 82, 100);
}

::-webkit-resizer {
  background-image: none;
  background-repeat: no-repeat;
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  margin-right: -6px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body {
    scrollbar-base-color: rgb(71, 82, 100);
    scrollbar-3dlight-color: rgb(245, 246, 250);
    scrollbar-arrow-color: rgb(245, 246, 250);
    scrollbar-darkshadow-color: rgb(245, 246, 250);
    scrollbar-face-color: rgb(71, 82, 100);
    scrollbar-highlight-color: rgb(245, 246, 250);
    scrollbar-shadow-color: rgb(245, 246, 250);
    scrollbar-track-color: rgb(245, 246, 250);
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .styled-scroll-for-ie {
    margin-right: -11px;
    margin-bottom: -17px;
    overflow: scroll;
  }
}

// Grid
.grid {
  //@for $i from 1 through 300 {
  //  > section:nth-of-type(#{$i}) {
  //    z-index: #{300 - $i};
  //  }
  //}

  //@for $i from 1 through 3000 {
  //  > *:nth-child(#{$i}) {
  //    z-index: -#{$i};
  //  }
  //}
}

//.adapt {
//  @for $i from 1 through 50 {
//    @media (max-width: #{1920 - ($i*1920/100)}px) {
//      zoom: #{1 - $i/100};
//    }
//  }
//}

#app.modified:before {
  z-index: 205;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 85px;
  background-color: rgba(0,0,0,0.3);
}

.capitalize {
  text-transform: capitalize;
}

.textLine > span {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.one-line-with-commas {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wordsWrap {
  white-space: pre-wrap;
}

.comma-separated-words >span {
  &:not(:last-child):after {
    content: ', ';
  }
}

.special-fields-container {
  label {
    z-index: 1;
    position: absolute;
    top: -7px;
    left: 5px;
    padding: 0 3px;
    background-color: white;
    color: #6c707d;
  }

  input,
  .selected-item,
  .selected__label{
    margin: 0 !important;
    font-size: 12px;
    font-weight: 500;
    color: #333333;

    &::placeholder,
    &.placeholder,
    .placeholder {
      color: #bdbdbd;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

#date-picker-portal {
  position: relative;
  z-index: 999999;
}

.alert-copy {
  z-index: 999999;
  display: flex;
  align-items: center;
  position: fixed;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  background-color: #3FB68E;
  color: white;
  padding: 8px;
  user-select: none;
  cursor: default;

  &__icon {
    font-size: 18px;
    margin-right: 1px;
  }

  &__text {
    font-size: 12px;
  }
}

@keyframes alertCopyAnimation {
  //75% {
  //  opacity: 1;
  //  transform: translate(-50%, 0);
  //}
  //100% {
  //  opacity: 0;
  //  transform: translate(-50%, -50%);
  //}
  50% {
    opacity: 1;
    //transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(50%);
  }
}

@keyframes blink {
  30% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 0.5s forwards;
}

@keyframes appearElement {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutElement {
  100% {
    height: 0;
    opacity: 0;
    padding: {
      top: 0;
      bottom: 0;
    };
    margin: 0;
  }
}

@keyframes ItemRotation {
  100% {
    transform: rotate(-360deg);
  }
}

.pre-wrap {
  white-space: pre-wrap;
}
.pre-line {
  white-space: pre-line;
}

.fade-out-element {
  animation: fadeOutElement 1s forwards;
}

.react-tooltip-custom-inner {
  opacity: unset !important;
  background: unset !important;
  border: unset !important;
  padding: unset !important;
  //margin: unset !important;

  &:after, &:before {
    content: none !important;
  }
}

.react-tooltip {
  font-size: 16px !important;

  &_no-opacity {
    opacity: unset !important;
  }
}


.__react_modal_image__modal_container {
  padding: 100px;
}
.react-simple-image-viewer__modal {
  z-index: 999999 !important;
}
